"use client";
export const maxDuration = 300;

import { Button } from "@/components/ui/button";
import { useEdgeStore } from '@/lib/edgestore';
import { useAppState } from "@/lib/utils/app-state";
import fetcher from "@/utils/fetcher";
import { PaperPlaneRight, Paperclip } from "@phosphor-icons/react";
import { generateId } from "ai";
import { useActions, useUIState } from "ai/rsc";
import { BookOpen, Lightbulb, Puzzle, Search } from "lucide-react";
import { useSession } from "next-auth/react";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import useSWR from 'swr';
import { BotMessage } from "./message";
import { Modal } from "./Modal";
import { Pricing } from "./pricing";
import { UserMessage } from "./user-message";
import AudioRecorder from "./artifacts/AudioRecorder";
import { ChatMessage } from "./artifacts/ChatMessage";
import { toast } from "sonner";

import type { AI, UIState } from '@/app/actions'
import Textarea from 'react-textarea-autosize'
import { Varela_Round } from 'next/font/google';

import { Label } from "@/components/ui/label"
import { usePathname } from 'next/navigation'


import {
  CornerDownLeft,
  Mic,
} from "lucide-react"
const varela = Varela_Round({
  weight: '400',
  subsets: ['latin'],
});

interface ChatPanelProps {
  messages: UIState
  query?: string
}

export function ChatPanel() {
  const pathname = usePathname()
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [input, setInput] = useState("");
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [imgPreview, setImgPreview] = useState("");
  const [reachedDailyLimit, setReachedDailyLimit] = useState<boolean>(false);
  const [showPricing, setShowPricing] = useState<boolean>(false)
  const [userIp, setUserIp] = useState(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { edgestore } = useEdgeStore();
  const { submit } = useActions();
  const [messageState, setMessages] = useUIState();
  const [isMobile, setIsMobile] = useState(false);
  const {
    isGenerating,
    setIsGenerating,
    autoScrollMode,
    setAutoScrollMode,
    isMessagesEmpty,
    setIsMessagesEmpty,
  } = useAppState();

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isTranformingSpeechToText, setIsTranformingSpeechToText] = useState<boolean>(false)

  const { data: userSessionData, status : userStatus } = useSession();

  const { data: dailyUsageData, } = useSWR(
    userSessionData?.user.id ? `/api/usage/daily?userId=${userSessionData.user.id}` : null,
    fetcher
  );

  const { data: userData, isLoading } = useSWR(
    userSessionData?.user.id ? `/api/user?id=${userSessionData.user.id}` : null,
    fetcher
  );

  useEffect(() => {
    // Define the breakpoint for mobile devices
    const MOBILE_BREAKPOINT = 768; // You can adjust this value as needed

    // Function to check screen width
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    if (userStatus != 'loading' && userStatus === 'unauthenticated') {
      const getUserIp = async () => {
        try {
          // Fetch user's public IP address
          const ipResponse = await fetch('https://api.ipify.org?format=json');
          const ipData = await ipResponse.json();
          const userIp = ipData.ip;
  
          // Fetch daily usage data by IP
          const { data: dailyUsageDataByIp } = await fetcher(
            userIp ? `/api/usage/daily?userId=${userIp}` : ''
          );

          // console.log('dailyUsageDataByIp =>', userIp, dailyUsageDataByIp?.nbQuery);
  
          if (dailyUsageDataByIp?.nbQuery > 5) {
            setReachedDailyLimit(true);
          }
        } catch (e: any) {
          // console.error('Error fetching user IP or daily usage data:', e);
        }
      };
      getUserIp();  
    }
  }, [userStatus]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData?.isActive) {
      setReachedDailyLimit(false);
      return;
    }

    if (dailyUsageData?.data?.chatStatus === false) {
      setReachedDailyLimit(true);
    }
  }, [userData, dailyUsageData]);

  useEffect(() => {
    async function fetchIp() {
      try {
        const response = await fetch('https://api.ipify.org?format=json'); // Fetches the user's public IP
        const data = await response.json();
        setUserIp(data.ip);
      } catch (error) {
        // console.error('Error fetching IP:', error);
      }
    }

    fetchIp();
  }, []);

  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      if (scrollTop < lastScrollTop) {
        onScrollUp();
        setAutoScrollMode(false);
      }
      setLastScrollTop(scrollTop);
    }
  };

  const onScrollUp = () => {
    // console.log("Scrolled up inside the div!");
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollTop]);

  useEffect(() => {
    let scrollInterval: NodeJS.Timeout | undefined;
    if (isGenerating && autoScrollMode) {
      scrollInterval = setInterval(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    }
    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [isGenerating, autoScrollMode]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "4rem";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (textareaRef.current.scrollHeight > 192) {
        textareaRef.current.style.height = "12rem";
      }
    }
  }, [input]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setImgFile(files[0]);
      setImgPreview(URL.createObjectURL(files[0]));
    }
  };

  const handleRemove = () => {
    setImgFile(null);
    setImgPreview("");
  };

  const handleSubmit = async (input_?: string) => {
    // if (input?.length < 1) {
    //   toast.error("Enter some text, to use the feature")
    //   return;
    // }

    setIsMessagesEmpty(false);
    setIsGenerating(true);
    setAutoScrollMode(true);

    type MessageContentType =
      | string
      | { type: "text"; text: string }
      | { type: "text"; text: string }[]
      | ({ type: "text"; text: string } | { type: "image"; image: string })[];

    let messageContent: MessageContentType;
    messageContent = input_ || input;

    if (imgFile) {
      try {
        const res = await edgestore.publicFiles.upload({
          file: imgFile
        });

        if (res.url) {
          messageContent = [
            { type: "text", text: input },
            {
              type: "image",
              image: res.url,
            },
          ];
        }
      } catch (error) {
        // console.error("Error uploading image: ", error);
        messageContent = [
          { type: "text", text: input },
          {
            type: "image",
            image: '',  // You can handle the fallback here
          },
        ];
      }
    }

    // Add user message to UI state
    setMessages((currentMessages: any) => [
      ...currentMessages,
      {
        id: generateId(),
        component: <UserMessage message={messageContent} />,
      },
    ]);

    const data = {
      userInput: {
        role: "user",
        content: messageContent,
      },
      // imgFile: imgFile || null,
    };

    setInput("");
    textareaRef.current?.focus();

    const responseMessage = await submit(data);


    await fetch(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: messageContent }),
    });

    setMessages((currentMessages: any) => [...currentMessages, responseMessage]);
    setImgFile(null);
    setImgPreview("");
    setIsGenerating(false);
  };

  const onTranscriptionComplete = (text: string) => {
    // console.log({ text });
    setInput(text);
    setIsTranformingSpeechToText(false);
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.error(err) // onNotAllowedOrFound
  );

  const questions = [
    "product (1-1/n^4), n=2 to infinity",
    "Explain theory of natural rights",
    "Give me a lecture on thermodynamics",
    "Show me videos and images famous Revolutions in History",
  ];

  return (
    <div>
    {pathname === '/' &&  isMessagesEmpty? (
      <div
        ref={scrollContainerRef}
        className={
          `fixed ${!isMobile? 'bottom-8' : 'bottom-[-60px]'} left-0 right-0 top-100 mx-auto h-screen ml-2 mr-2 flex flex-col items-center justify-center`
        }
      >
        <div className="flex flex-col items-center justify-center mb-8 m-2">
          <span className={`${varela.className} text-[4.45rem] text-center`}>Fetchit</span>
          <span className="text-[2.05rem] font-semibold text-center">
            Discover <span className={`bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent`}>Smarter</span> Learning
          </span>
        </div>
        {imgFile && (
          <div className="flex flex-row mls-10 w-[870px] p-1 relative">
            <Image
              className="border-[2px] border-blue-200 bg-gray-100 rounded-md"
              src={imgPreview}
              alt="File Thumbnail"
              width={150}
              height={150}
              objectFit="contain"
            />
            <button
              onClick={handleRemove}
              className="absolute bg-red-500 text-white border-none rounded-full w-5 h-5 cursor-pointer text-center leading-5 text-sm"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              X
            </button>
          </div>
        )}
        <div className="relative flex flex-col w-full max-w-[870px] ml-2 mr-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 p-[3px] lg:col-span-2">
          <div
            className="relative overflow-hidden rounded-lg border bg-background shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
          >
            <Label htmlFor="message" className="sr-only">
              Message
            </Label>
            <Textarea
              id="message"
              placeholder="Ask anything..."
              className="min-h-12 max-h-[300px] w-full resize-none border-0 p-2 md:p-4 shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
              value={input}
              disabled={reachedDailyLimit}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (!isRecording || !isTranformingSpeechToText || input.length > 1) {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }
              }}
            />

          <div className="flex items-center p-3 pt-0">
            <Button className={`${reachedDailyLimit && 'hidden'}`} variant="ghost" size="icon" onClick={handleButtonClick}>
              <Paperclip className="size-4" />
              <span className="sr-only">Attach file</span>
            </Button>
            {/* <Button variant="ghost" size="icon" disabled={isGenerating} id="mic_button">
              <div role="button">
                <AudioRecorder 
                  isRecording={isRecording} 
                  setIsRecording={setIsRecording} 
                  onTranscriptionComplete={onTranscriptionComplete} 
                  setIsTranformingSpeechToText={setIsTranformingSpeechToText} 
                />
              </div>
            </Button> */}

            {userStatus != 'loading' && userStatus === 'unauthenticated' && reachedDailyLimit &&
              <div className="flex items-center justify-center w-full">
                <span className={`${varela.className} text-[1rem] text-center mt-2 bg-clip-text text-reds-600`}>Login to continue searching...</span>
              </div>
            }

            {userStatus != 'loading' && userStatus === 'authenticated' && reachedDailyLimit &&
              <div className="flex items-center justify-center w-full">
                <span className={`${varela.className} text-[1rem] text-center mt-2 bg-clip-text text-reds-600`}>We've used up all of free daily usage. Upgrade to pro to continue searching...</span>
              </div>
            }

            <Button
              type="submit" 
              size="sm" 
              disabled={reachedDailyLimit}
              className={`ml-auto gap-1.5 bg-black rounded ${reachedDailyLimit && 'hidden'}`}
              onClick={() => handleSubmit()}
            >
              Ask Away
              <CornerDownLeft className="size-3.5" />
            </Button>
          </div>
          {userStatus != 'loading' && userStatus === 'authenticated' && reachedDailyLimit &&
            <div className="flex items-center justify-center w-full">
              <span className={`${varela.className} text-black text-[2rem]`}>$5.99</span><span className={`${varela.className} text-lg font-normal`}>/month. Enough for a coffee <span className="text-[2rem] align-middle">☕</span></span>
              <Button
              type="submit" 
              size="sm" 
              disabled={!reachedDailyLimit}
              className="ml-2 bg-gradient-to-r from-blue-500 to-purple-600 rounded-md"
              onClick={() => setShowPricing(true)}
            >
              Go pro
            </Button>
            </div>
          }
          </div>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            disabled={reachedDailyLimit}
            style={{ display: 'none' }}
          />
        </div>

        {/* <div className="relative w-full">
          <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full px-4">
            <div className="text-center">
              <span className={`${varela.className} text-center text-[1rem] bg-clip-text`}>
                <span className={`${varela.className} text-[1rem] mt-2 bg-clip-text text-red-600`}>
                  Disclaimer:{' '}
                </span>
                Fetchit is designed to enhance understanding, not for quick answers or cheating. */}
                {/* 
                <span className={`${varela.className} text-[1rem] mt-2 bg-clip-text text-blue-600`}>
                  {' '}
                  Why?
                </span> 
                */}
              {/* </span>
            </div>
          </div>
        </div> */}

        <div className={`max-w-[900px] ${isMobile && 'max-h-[200px]'} mt-10 mx-auto overflow-y-auto`}>
          <div className="flex flex-wrap items-center justify-center gap-2">
            {questions.map((question, index) => (
              <div key={index} className="w-full sm:w-auto">
                <Button
                  type="button"
                  variant="outline"
                  className="items-center mt-2 ml-1s px-4 py-2 border-gray-300 text-gray-100 rounded-md bg-[#1692ff] hover:bg-gray-100 whitespace-normal break-words"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default behavior
                    handleSubmit(question); // Trigger submit function
                  }}
                >
                  <BotMessage content={question} />
                </Button>
              </div>
            ))}
          </div>
        </div>
        <Modal
          isOpen={showPricing}
          onOpenChange={setShowPricing}
          width={900}
        >
          <Pricing />
        </Modal>
      </div>
    ) : (
      <div
        className={
          'fixed bottom-2 left-0 right-0 top-100 mx-auto ml-2 mr-2 flex flex-col items-center justify-center'
        }
      >
        {imgFile && (
          <div className="flex flex-row mls-10 w-[870px] p-1 relative">
            <Image
              className="border-[2px] border-blue-200 bg-gray-100 rounded-md"
              src={imgPreview}
              alt="File Thumbnail"
              width={150}
              height={150}
              objectFit="contain"
            />
            <button
              onClick={handleRemove}
              className="absolute bg-red-500 text-white border-none rounded-full w-5 h-5 cursor-pointer text-center leading-5 text-sm"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              X
            </button>
          </div>
        )}
        <div className="relative flex flex-col w-full max-w-[870px] ml-2 mr-2 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 p-[3px] lg:col-span-2">
          <div
            className="relative overflow-hidden rounded-lg border bg-background shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
          >
            <div className="flex items-center p-3s pl-3 pr-3 pt-0">
              <Button variant="ghost" size="icon" onClick={handleButtonClick}>
                <Paperclip className="size-4" />
                <span className="sr-only">Attach file</span>
              </Button>
              {/* <Button variant="ghost" size="icon" disabled={isGenerating} id="mic_button">
                <div role="button">
                  <AudioRecorder 
                    isRecording={isRecording} 
                    setIsRecording={setIsRecording} 
                    onTranscriptionComplete={onTranscriptionComplete} 
                    setIsTranformingSpeechToText={setIsTranformingSpeechToText} 
                  />
                </div>
              </Button> */}
              <Label htmlFor="message" className="sr-only">
                Message
              </Label>
              <Textarea
                id="message"
                placeholder="Ask a follow up question"
                className="min-h-12 max-h-[300px] w-full resize-none border-0 p-2 md:p-4 shadow-none focus:outline-none focus-visible:ring-0 focus-visible:border-none"
                value={input}
                disabled={reachedDailyLimit}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (!isRecording || !isTranformingSpeechToText || input.length > 1) {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                }}
              />
              <Button
                type="submit" 
                size="sm" 
                className="ml-auto gap-1.5 bg-black rounded"
                onClick={() => handleSubmit()}
              >
                Ask
                <CornerDownLeft className="size-3.5" />
              </Button>
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            disabled={reachedDailyLimit}
            style={{ display: 'none' }}
          />
        </div>
      </div>
    )}
    </div>
  );
}
