"use client";

import React, { useEffect, useState } from "react";
import { PartialTeaching } from "@/lib/schema/outputs";
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { BotMessage } from "./message";
import {
  Sparkles,
  Image,
  TvMinimalPlay,
  ListOrdered,
  ListTodo,
  Volume2,
  Dog,
  Globe
} from "lucide-react";
import {
  StreamableValue,
  useActions,
  useStreamableValue,
  useUIState,
} from "ai/rsc";
import type { AI } from "@/app/actions";
import { useAppState } from "@/lib/utils/app-state";
import { UserMessage } from "./user-message";
import { generateId } from "ai";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { Varela_Round } from 'next/font/google';
const varela = Varela_Round({
  weight: '400',
  subsets: ['latin'],
});

type TooltipButtonProps = {
  children: React.ReactNode;
  tooltipText: string;
};

const TooltipButton: React.FC<TooltipButtonProps> = ({
  children,
  tooltipText,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>
          <span>{tooltipText}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export type CopilotProps = {
  response?: StreamableValue<PartialTeaching>;
};

export const CopilotTeach: React.FC<CopilotProps> = ({
  response,
}: CopilotProps) => {
  const [data, error, pending] = useStreamableValue<PartialTeaching>(response);
  const [object, setObject] = useState<PartialTeaching>();
  const [, setMessages] = useUIState<typeof AI>();
  const { submit } = useActions();
  // const { setIsGenerating } = useAppState()
  const { isGenerating, setIsGenerating, autoScrollMode, setAutoScrollMode } =
    useAppState();
  const [itStated, setItStarted] = useState(false);
  // const { autoScrollMode, setAutoScrollMode } = useAppState();

  useEffect(() => {
    // console.log('Show me the values 0.0 =>', JSON.stringify(data, null, 2));
    if (!data) return;
    setObject(data);
    setItStarted(true);
    // console.log('Show me the values 1.0 =>', object)
  }, [data]);

  useEffect(() => {
    if (!pending) {
      setIsGenerating(false);
    }
  }, [pending]);

  const onFormSubmit = async (
    e: React.FormEvent<HTMLFormElement> | any,
    value: string
  ) => {
    e.preventDefault();

    // if (isGenerating) return
    setIsGenerating(true);
    setAutoScrollMode(true);

    const queryString = value;
    // console.log("Show me submit e value new =>", queryString);

    setMessages((currentMessages: any) => [
      ...currentMessages,
      {
        id: generateId(),
        component: <UserMessage message={queryString} />,
      },
    ]);

    const data = {
      userInput: {
        role: "user",
        content: queryString,
      },
    };
    const response = await submit(data);
    setMessages((currentMessages) => [...currentMessages, response]);

    await fetch(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: queryString }),
    });
  };

  if (error) {
    return (
      <Card className="p-4 w-full flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Sparkles className="w-4 h-4" />
          <h5 className="text-muted-foreground text-xs truncate">
            {`error: ${error}`}
          </h5>
        </div>
      </Card>
    );
  }

  return (
    <div className={`${varela.className}`}>
      {itStated ? (
        <Card className="p-5 rounded-xl w-full mx-auto bg-white shadow-lg transition-all">
          <div
            className={`ml-2 ${pending ? "text-red-600" : "text-green-600"}`}
          >
            <Dog size={16} className="mr-1" />
          </div>

          <div
            className={object?.subject && `mb-3 border-b pb-3 border-gray-300`}
          >
            <h2 className="text-lg font-bold text-gray-800 ml-2">
              {object?.subject}
            </h2>
            <p className="text-sm text-gray-600 ml-2">{object?.topics}</p>
          </div>

          <div className="space-y-4">
            {object?.explanation?.map((explanation, index) => (
              <div
                key={`explanation-${index}`}
                className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors relative group"
              >
                <div className="mb-2">
                  <h3 className="text-md font-bold text-gray-800">
                    <BotMessage content={explanation?.title as string} />
                  </h3>
                  <p className="text-sm text-gray-600 italic">
                    <BotMessage content={explanation?.lecture as string} />
                  </p>
                </div>
                <div className="pl-4 mt-2 space-y-2 border-l-4 border-blue-400">
                  {explanation?.breakdowns?.map((breakdown, breakdownIndex) => (
                    <div
                      key={`breakdown-${breakdownIndex}`}
                      className="text-sm text-gray-700"
                    >
                      <p className="flex items-centers">
                        <span className="mr-1 font-medium text-blue-800">
                          {breakdown?.step}:
                        </span>
                        <span className="flex-1">
                          <BotMessage content={breakdown?.description as string} />
                        </span>
                      </p>

                      {/* {breakdown?.visuals &&
                        breakdown?.visuals.map((visual, visualIndex) => (
                          <div
                            key={`visual-${visualIndex}`}
                            className="flex items-start space-x-4 mb-4"
                          >
                            <div className="w-1/6 flex-shrink-0">
                              <a
                                href={visual?.visual}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={visual?.visual}
                                  alt={visual?.description}
                                  className="rounded-md transition-transform transform hover:scale-150 duration-300"
                                  onError={(e) =>
                                    (e.currentTarget.src =
                                      "https://files.ds106.us/wp-content/uploads/sites/4/2014/12/placeholder.png")
                                  }
                                />
                              </a>
                            </div>

                            <p className="text-xs text-gray-500 italic mt-2">
                              <BotMessage content={breakdown?.description as string} />
                            </p>
                          </div>
                        ))} */}
                    </div>
                  ))}
                </div>
                {explanation?.answer && (
                  <div className="mt-4 flex items-center">
                    <p className="text-md font-semibold text-gray-800 flex">
                      <span className="mr-1 text-blue-500 font-medium">Answer: </span>
                      <BotMessage content={explanation?.answer as string} />
                    </p>
                  </div>
                )}

                <div className="flex justify-end space-x-2 mt-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-4 right-4">
                  <TooltipButton tooltipText="Get Answers in Lecture Format!">
                    <div className="p-[1.5px] bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg inline-block">
                      <Button
                        type="button"
                        variant="outline"
                        className="flex items-center px-3 py-2 bg-white rounded-md border-none"
                        disabled={pending}
                        value={`Lecture me on ${explanation?.title}`}
                        onClick={(e) => onFormSubmit(e as any, `Lecture me on ${explanation?.title}`)}
                      >
                        <Volume2 size={16} className="mr-1" />
                        Lecture
                      </Button>
                    </div>
                  </TooltipButton>
{/* 
                  <TooltipButton tooltipText="Get Most Relevant Images!">
                    <Button
                      type="button"
                      variant="outline"
                      className="flex items-center px-3 py-2"
                      disabled={pending}
                      value={object?.imagesSearchButton}
                      onClick={(e) =>
                        onFormSubmit(e as any, object?.imagesSearchButton as string)
                      }
                    >
                      <Image size={16} className="mr-1" />
                      Images
                    </Button>
                  </TooltipButton>

                  <TooltipButton tooltipText="Get Relevant Videos!">
                    <Button
                      type="button"
                      variant="outline"
                      className="flex items-center px-3 py-2"
                      disabled={pending}
                      value={object?.videosSearchButton}
                      onClick={(e) =>
                        onFormSubmit(e as any, object?.videosSearchButton as string)
                      }
                    >
                      <TvMinimalPlay size={16} className="mr-1" />
                      Videos
                    </Button>
                  </TooltipButton> */}
                </div>
              </div>
            ))}
          </div>

          {!pending && (
            <div className="flex flex-col sm:flex-row sm:justify-end sm:space-x-2 mt-4">
              <TooltipButton tooltipText="Get Answers in Lecture Format!">
                <Button
                  type="button"
                  variant="outline"
                  className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                  disabled={pending}
                  value="Lecture me"
                  onClick={(e) => onFormSubmit(e as any, "Lecture me")}
                >
                  <Volume2 size={16} className="mr-1" />
                  Lecture
                </Button>
              </TooltipButton>

              <TooltipButton tooltipText="Get Most Relevant Images!">
                <Button
                  type="button"
                  variant="outline"
                  className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                  disabled={pending}
                  value={`Find me images for ${object?.subject}`}
                  onClick={(e) =>
                    onFormSubmit(e as any, `Find images for: ${object?.subject}`)
                  }
                >
                  <Image size={16} className="mr-1" />
                  Images
                </Button>
              </TooltipButton>

              <TooltipButton tooltipText="Get Relevant Videos!">
                <Button
                  type="button"
                  variant="outline"
                  className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                  disabled={pending}
                  value={`Find me videos for ${object?.videosSearchButton}`}
                  onClick={(e) =>
                    onFormSubmit(
                      e as any,
                      `Find videos for: ${object?.videosSearchButton}`
                    )
                  }
                >
                  <TvMinimalPlay size={16} className="mr-1" />
                  Videos
                </Button>
              </TooltipButton>

              <TooltipButton tooltipText="Search the web!">
                <Button
                  type="button"
                  variant="outline"
                  className="flex items-center px-3 py-2 mt-2 sm:mt-0"
                  disabled={pending}
                  value={`Web search: ${object?.videosSearchButton}`}
                  onClick={(e) =>
                    onFormSubmit(
                      e as any,
                      `Web search: ${object?.videosSearchButton}`
                    )
                  }
                >
                  <Globe size={16} className="mr-1" />
                  Web
                </Button>
              </TooltipButton>
            </div>
          )}

          {object?.followUpRequests && (
            <div className="flex flex-col">
              <span className="text-lg w-full text-blue-500 "> You may also want to ask</span>
                <div className="flex items-center flex-wrap space-y-2">
                  {object?.followUpRequests?.map((followup, index) => (
                    <Button
                      key={index}
                      type="button"
                      variant="outline"
                      className="flex items-center mt-2 ml-1 px-4 py-2 border-gray-300 text-gray-700 rounded hover:bg-gray-100 whitespace-normal break-words"
                      disabled={pending}
                      value={followup?.request}
                      onClick={(e) =>
                        onFormSubmit(e as any, followup?.request as string)
                      }
                    >
                      <ListOrdered size={16} className="mr-2" />
                      <BotMessage content={followup?.request as string} />
                    </Button>
                  ))}
                </div>
            </div>
          )}
        </Card>
      ) : (
        <div className="flex flex-col gap-2 py-2">
          <Skeleton className="h-6 w-48" />
          <Skeleton className="w-full h-6" />
        </div>
      )}
    </div>
  );
};
